<template>
  <div class="product-list">
    <div
      v-for="(rowProducts, rowIndex) in chunkedProductData"
      :key="rowIndex"
      class="product-row"
    >
      <div
        v-for="(product, index) in rowProducts"
        :key="index"
        class="product-grid"
      >
        <div class="product">
          <div class="product-image">
            <img :src="product.imageUrl" :alt="product.name" />
          </div>
          <div class="product-info">
            <h3 class="product-name">{{ product.name }}</h3>
            <p class="p-price">价格：{{ product.price }}</p>
            <p class="p-spec">规格：{{ product.spec }}</p>
            <p class="p-storage">储藏方法：{{ product.storageMethod }}</p>
            <p class="p-shelf-life">保质期：{{ product.shelfLife }}</p>
            <p class="p-origin">产地：{{ product.origin }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductDisplay",
  data() {
    return {
      productData: [
        {
          name: "中猕二号猕猴桃",
          imageUrl: require("@/assets/img/中猕二号.jpg"),
          price: "108元（一级果，18颗装）",
          spec: "2kg/箱",
          storageMethod: "冷藏(0~5°C)",
          shelfLife: "六个月",
          origin: "云南楚雄",
        },
        {
          name: "金艳猕猴桃",
          imageUrl: require("@/assets/img/金桃猕猴桃.png"),
          price: "58元（一级果，18颗装）",
          spec: "2kg/箱",
          storageMethod: "冷藏(0~5°C)",
          shelfLife: "六个月",
          origin: "云南楚雄",
        },
        {
          name: "徐香猕猴桃",
          imageUrl: require("@/assets/img/徐香猕猴桃.png"),
          price: "88元（一级果，18颗装）",
          spec: "2kg/箱",
          storageMethod: "冷藏(0~5°C)",
          shelfLife: "六个月",
          origin: "云南楚雄",
        },
        {
          name: "突尼斯软籽石榴",
          imageUrl: require("@/assets/img/石榴.jpg"),
          price: "68元/箱",
          spec: "3kg/箱",
          storageMethod: "冷藏/常温",
          shelfLife: "30天",
          origin: "云南楚雄",
        },
        {
          name: "猕猴桃汁饮品",
          imageUrl: require("@/assets/img/must1.jpg"),
          price: "72元/箱",
          spec: "300ml*12瓶/箱",
          storageMethod: "常温",
          shelfLife: "12个月",
          origin: "云南楚雄",
        }
      ],
    };
  },
  computed: {
    chunkedProductData() {
      const chunkSize = 3; // Number of products per row
      const chunks = [];
      for (let i = 0; i < this.productData.length; i += chunkSize) {
        chunks.push(this.productData.slice(i, i + chunkSize));
      }
      return chunks;
    },
  },
};
</script>

<style scoped>
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px; /* Adjust as needed */
}

.product-grid {
  flex-basis: calc(33.33% - 30px); /*Adjust as needed */
  margin: 15px; /* Adjust as needed */
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 425px;
}

.product-row {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
}

.product-info {
  padding: 10px;
}

.product-name {
  text-align: center;
  font-size: 20px;
  color: #333;
  margin-bottom: 5px;
}

.p-description {
  font-size: 14px;
  color: rgb(99, 108, 87);
}

.product-image {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image img {
  max-width: auto;
  height: 300px;
  border-radius: 10px;
}

.product-image:hover img {
  transform: scale(1.05);
}

.p-price,
.p-spec,
.p-storage,
.p-shelf-life,
.p-origin {
  font-size: 16px;
  color: #666;
  margin-top: 5px;
  text-align: center;
}
</style>

import { createStore } from 'vuex';

export default createStore({
  state: {
    activeMenu: sessionStorage.getItem('activeMenu') || 'home',
    isnews: false,
    news: [],
    dataFetched: false,
    introContent: "",
    founderDetails: "",
    comCulture: "",
    honour: "",
    isLogin: false,
  },
  getters: {
  },
  mutations: {
    setActiveMenu(state, menu) {
      state.activeMenu = menu;
      sessionStorage.setItem('activeMenu', menu);
    },
    setActiveisnews(state, menu) {
      state.isnews = menu;
    },
    setActivenews(state, menu) {
      state.news = menu;
    },
    setdataFetched(state, menu) {
      state.dataFetched = menu;
    },
    setintroContent(state, menu) {
      state.introContent = menu;
    },
    setfounderDetails(state, menu) {
      state.founderDetails = menu;
    },
    setcomCulture(state, menu) {
      state.comCulture = menu;
    },
    sethonour(state, menu) {
      state.honour = menu;
    },
    setisLogin(state, menu) {
      state.isLogin = menu;
    }
  },
  actions: {
  },
  modules: {
  }
})

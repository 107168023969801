<template>
  <headercom-vue></headercom-vue>
  <div class="product-display">
    <section class="product-details">
      <div class="container">
        <div class="product-info">
          <div class="location-image">
            <img src="@/assets/img/风景.jpg" alt="Chu Oranges Image" />
          </div>
          <div class="location-description">
            <div class="text-box">
              <p class="text-content"></p>
              <h4>
                <span class="product-title">猕猴桃</span>
                <p class="product-paragraph">/产地</p>
              </h4>
              <p class="text-content"></p>
              <p class="product-paragraph">
                云南省楚雄彝族自治州楚雄市东华镇猕猴桃基地
              </p>
              <p class="text-content"></p>
              <p class="text-content"></p>
              <p class="product-subtitle">
                猕猴桃基地位于楚雄市，高海拔、低纬度，
              </p>
              <p class="product-subtitle">
                日照时间长、昼夜温差大、降雨量适中，
              </p>
              <p class="product-subtitle big">
                <strong
                  >独特的高原气候孕育出了高品质的“彝运”猕猴桃，软糯甘香，回味无穷。</strong
                >
              </p>
              <p class="product-subtitle big">
                <strong
                  >不闻人间烟火，但食人间美味，超凡的味道，超凡的体验，超凡的享受。</strong
                >
              </p>
              <p class="product-subtitle">
                2017 年 1 月被认定为绿色食品 A 级产品。
              </p>
            </div>
          </div>
          <div class="contact-box">
            <div class="new-row">
              <div class="new-column">
                <p class="new-text-title">
                  <span class="custom-font-size1">彝 </span>
                  <span class="custom-font-size2"> 心 </span>
                  <span class="custom-font-size3"> 德</span>
                </p>
                <p class="new-text">红 心 蕴 天 机</p>
                <p class="new-text">运 来 谁 堪 敌</p>
                <p class="new-text">弥 望 是 绿 海</p>
                <p class="new-text">桃 下 已 成 蹊</p>
                <p class="new-text">造 化 缘 信 诚</p>
                <p class="new-text">福 至 由 汗 滴</p>
                <p class="new-text">楚 天 一 农 夫</p>
                <p class="new-text">雄 起 在 彝 地</p>
              </div>
              <div class="new-column">
                <img
                  src="@/assets/img/培育1.jpg"
                  alt="图片4"
                  class="new-image"
                />
              </div>
              <div class="new-column">
                <img
                  src="@/assets/img/培育2.jpg"
                  alt="图片5"
                  class="new-image"
                />
              </div>
              <div class="new-column">
                <img
                  src="@/assets/img/培育3.jpg"
                  alt="图片6"
                  class="new-image"
                />
              </div>
              <div class="new-column">
                <img
                  src="@/assets/img/培育4.jpg"
                  alt="图片7"
                  class="new-image"
                />
              </div>
            </div>
          </div>
          <div class="highlight-container">
            <div class="highlight">
              <span class="red" style="font-size: 34px">因</span>
              <span class="green" style="font-size: 38px">为</span>
              <span class="blue" style="font-size: 42px">“猕”</span>
              <span class="red" style="font-size: 38px">上</span>
              <span class="green" style="font-size: 34px">你</span>
              <span class="blue" style="font-size: 38px">，所</span>
              <span class="red" style="font-size: 34px">以</span>
              <span class="green" style="font-size: 43px">“桃”</span>
              <span class="blue" style="font-size: 38px">不</span>
              <span class="red" style="font-size: 34px">了</span>
            </div>
          </div>
          <Products></Products>
        </div>
      </div>
    </section>
    <FootercomVue></FootercomVue>
  </div>
</template>
<script>
import Carousel from "vue-carousel";
import FootercomVue from "@/components/Footercom.vue";
import HeadercomVue from "@/components/Headercom.vue";
import Products from "@/views/Products.vue";

export default {
  name: "ProductsPage",
  components: {
    Carousel,
    FootercomVue,
    HeadercomVue,
    Products,
  },
};
</script>
<style scoped>
.product-display {
  background-color: #f5f5f5;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
}

.product-info {
  display: flex;
  flex-wrap: wrap;
}

.location-image {
  flex: 1;
  margin-right: 20px;
}

.location-image img {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
}

.location-description {
  text-align: center;
  flex: 1;
}

.text-box {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.product-info h4 {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.product-title {
  font-size: 45px;
  background-image: linear-gradient(to right, rgb(127, 171, 127), #56a242);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.product-subtitle {
  line-height: 2;
  font-size: 15px;
  text-align: justify;
  margin-left: 30px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding: 5px 0;
}

.big {
  font-size: 18px;
}
.product-paragraph {
  margin-bottom: 0px;
  font-size: 29px;
  text-align: justify;
  margin-left: 30px;
  background-image: linear-gradient(to right, black, #2d2533);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.jinweidu {
  margin-bottom: 0px;
  line-height: 2;
  font-size: 23px;
  text-align: justify;
  margin-left: 30px;
  color: gray;
}

.product-new {
  margin-bottom: 0px;
  line-height: 1.7;
  font-size: 16px;
  text-align: justify;
  margin-left: 30px;
  font-weight: bold;
}

.text-new {
  margin-bottom: 0px;
  line-height: 1.7;
  font-size: 14px;
  text-align: justify;
  margin-left: 30px;
}

.text-content {
  padding-top: 1em;
}

.product-tile {
  margin-bottom: 0px;
  line-height: 2;
  font-size: 25px;
  text-align: justify;
  margin-left: 30px;
  height: 50px;
}

.new-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
}

.new-column {
  flex: 1;
  margin-right: 20px;
  margin-left: 10px;
  position: relative;
}

.new-text {
  margin: 0;
  font-size: 22px;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-text-title {
  color: red;
  font-style: italic;
  margin-bottom: 10px;
  font-size: 25px;
}

.custom-font-size1 {
  font-size: 28px; /* 设置第一个字的字体大小 */
}

.custom-font-size2 {
  font-size: 22px; /* 设置第二个字的字体大小 */
}

.custom-font-size3 {
  font-size: 40px; /* 设置第三个字的字体大小 */
}

.new-column img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.new-image {
  max-width: 100%;
  height: auto;
}

.new-column:hover .new-image {
  transform: scale(1.1);
  filter: brightness(120%);
  transition: filter 0.3s ease;
}

.contact-box {
  background-color: white;
  padding: 10px;
}

.trait {
  font-size: 18px;
  text-align: left;
  margin-left: 20px;
  padding: 10px;
  color: green;
}

.two {
  color: rgb(151, 151, 61);
}

.highlight-container {
  display: block;
  text-align: center;
  margin: 30px auto; /* 通过 margin 居中显示 */
}

/* 为 highlight 添加样式，保持原有的样式 */
.highlight {
  font-size: 35px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.highlight span {
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 0 25px;
}

.highlight span:hover {
  transform: rotate(360deg);
}

.red {
  background-image: linear-gradient(to right, #d02d2d, #d7e90e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px #e0dcc1;
}
.green {
  background-image: linear-gradient(to right, #00ff00, #c6b81c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px #d9edd9;
}

.blue {
  background-image: linear-gradient(to right, #0f22ee, #980af1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px #6f78d8;
}
</style>


<template>
  <headercom-vue></headercom-vue>
  <div class="history">
    <div class="first-profile">
      <div class="contact-header">
        <div class="contact-title" colspan="4">
          <p class="toptext-title">公司简介</p>
          <p class="product-title">Company Profile</p>
        </div>
      </div>
      <div class="company-profile">
        <div class="profile-content">
          <div class="text-title" v-html="introContent"></div>
          <div class="profile-img">
            <div class="image1">
              <img src="./../assets/img/轮播2.jpg" alt="" />
            </div>
            <div class="image2">
              <img src="./../assets/img/公司.jpg" alt="" />
              <img src="./../assets/img/公司1.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="two-profile">
      <div class="contact-header">
        <div class="contact-title" colspan="4">
          <p class="toptext-title">法人简介</p>
          <p class="product-title">Introduction to the legal entity</p>
        </div>
      </div>
      <div class="company-profile">
        <div class="profile-content">
          <div class="text-title legal" v-html="founderDetails"></div>
          <div class="legal-img">
            <img src="./../assets/img/法人.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="three-profile">
      <div class="contact-header">
        <div class="contact-title" colspan="4">
          <p class="toptext-title">企业文化</p>
          <p class="product-title">Corporate Culture</p>
        </div>
      </div>
      <div class="company-profile">
        <div class="profile-content">
          <div class="text-title culture" v-html="comCulture"></div>
        </div>
      </div>
    </div>
    <div class="four-profile">
      <div class="contact-header">
        <div class="contact-title" colspan="4">
          <p class="toptext-title">资质荣誉</p>
          <p class="product-title">Qualification Honor</p>
        </div>
      </div>
      <div class="company-profile">
        <div class="profile-content">
          <div class="text-title honor" v-html="honour"></div>
          <div class="legal-img">
            <img src="./../assets/img/荣誉1.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <footercom-vue></footercom-vue>
</template>
<script>
import FootercomVue from "@/components/Footercom.vue";
import HeadercomVue from "@/components/Headercom.vue";
import axios from "axios";
import { mapMutations, useStore } from "vuex";

export default {
  name: "History",
  data() {
    return {
      introContent: "",
      founderDetails: "",
      comCulture: "",
      honour: "",
    };
  },
  components: {
    FootercomVue,
    HeadercomVue,
  },
  created() {
    const store = useStore();
    // 使用缓存数据来避免重复请求
    if (store.state.dataFetched) {
      this.introContent = store.state.introContent;
      this.founderDetails = store.state.founderDetails;
      this.comCulture = store.state.comCulture;
      this.honour = store.state.honour;
    } else {
      this.fetchData();
    }
  },
  methods: {
    ...mapMutations([
      "setdataFetched",
      "setintroContent",
      "setfounderDetails",
      "setcomCulture",
      "sethonour",
    ]),
    fetchData() {
      // 发送异步请求，获取数据
      axios
        .get("http://www.cxljr.com:8080/company_data")
        .then((response) => {
          const data = response.data;
          this.introContent = data.com_profile;
          this.founderDetails = data.legal_profile;
          this.comCulture = data.com_culture;
          this.honour = data.honour;
          this.setdataFetched(true); // 数据获取成功后标记为已获取
          this.setintroContent(this.introContent);
          this.setfounderDetails(this.founderDetails);
          this.setcomCulture(this.comCulture);
          this.sethonour(this.honour);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
<style scoped>
.history {
  padding-bottom: 10px;
}

.first-profile {
  background-color: whitesmoke;
}

.two-profile {
  background-color: aliceblue;
}

.four-profile {
  background-color: rgb(240, 243, 246);
}

.profile-content {
  display: flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中对齐 */
  padding: 0 20px;
  padding-bottom: 20px;
}

.text-title {
  flex: 1; /* 自动占据剩余空间 */
  width: 50%;
  font-family: "Franklin Gothic Medium";
  font-size: 22px;
  text-align: left;
  text-indent: 2em;
}

.text-title:hover {
  color: rgb(45, 85, 43);
  font-weight: bold;
}

.text-title strong:hover {
  font-size: 28px;
  color: rgb(110, 220, 102);
  font-weight: bold;
}

.profile-img {
  flex-basis: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

.image1 {
  flex-basis: 100%;
  margin: 10px;
}

.image1 img {
  border-radius: 10px;
}

.image2 {
  flex-basis: 47.2%;
  display: flex;
}

.image2 img {
  padding: 0 10px;
  border-radius: 12px;
}

.profile-img img {
  width: 100%;
  height: 220px; /* 为所有图片设置固定高度 */
}

.legal-img {
  flex-basis: 50%;
  display: flex;
  flex-wrap: wrap;
}

.legal-img img {
  width: 70%;
  height: 320px;
  margin-left: 100px;
  border-radius: 10px;
}

.legal {
  text-align: justify; /* 文本两端对齐 */
  margin-left: 50px;
}

.culture {
  text-align: center;
  margin-bottom: 30px;
}

.honor {
  margin-left: 50px;
}
</style>
<template>
  <div class="home">
    <Headercom></Headercom>
    <div class="contact-box">
      <div class="contact-header">
        <div class="contact-title" colspan="4">
          <p class="toptext-title">猕 / 猴 / 桃</p>
          <p class="product-title">Kiwifruit</p>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <div class="fruit-info">
            <p class="info-text">
              <span class="word1">猕猴桃</span>（Actinidia chinensis
              Planch），又称狐狸桃、藤梨、羊桃、木子、毛木果、奇异果、麻藤果等。
              果形一般为椭圆状，外观呈绿褐色，表皮覆盖浓密绒毛，不可食用，内含亮绿色果肉和一排黑色种子。
              因猕猴喜食而得名，也有说法是因为果皮覆盖毛，形似猕猴。是一种品质鲜嫩、营养丰富、风味鲜美的水果。
            </p>
            <p class="info-text">
              猕猴桃质地柔软，口感酸甜，味道被形容为草莓、香蕉、菠萝的混合。
              除含有猕猴桃碱、蛋白水解酶、单宁果胶、糖类等有机物，还富含钙、钾、硒、锌、锗等微量元素，以及人体所需的17种氨基酸。
              此外，猕猴桃还富含丰富的维生素C、葡萄酸、果糖、柠檬酸、苹果酸、脂肪。
            </p>
            <p class="info-text">
              <span class="word2"
                >好吃是衡量一个水果的首要标准，好水果生于自然，源于自然。不需要催熟、防腐或人工处理，树熟状态还原水果的原有风味。</span
              >
            </p>
          </div>
        </div>
        <div class="column">
          <div class="divide">
            <div class="kiwi-image">
              <img src="@/assets/img/kiwi2.jpg" alt="照片" />
            </div>
            <div class="divide-p">
              <div class="feature">
                <h3>皮薄肉多</h3>
                <p class="des">果肉翠绿 肉质鲜嫩</p>
                <p class="des">轻轻一咬 回味无穷</p>
              </div>
              <div class="divider"></div>
              <div class="feature">
                <h3>营养丰富</h3>
                <p class="des">富含维生素 氨基酸</p>
                <p class="des">钙 叶酸等营养</p>
              </div>
              <div class="divider-d"></div>
              <div class="feature">
                <h3>老少皆宜</h3>
                <p class="des">果子汁水充沛</p>
                <p class="des">入口鲜甜 食用佳选</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footercom></Footercom>
  </div>
</template>

<script>
import Headercom from "@/components/Headercom.vue";
import Footercom from "@/components/Footercom.vue";

export default {
  name: "ContactPage",
  components: {
    Headercom,
    Footercom,
  },
};
</script>

<style>
/* 添加的样式 */
.contact-box {
  padding: 10px;
  margin-left: 5%;
  margin-right: 5%;
}

.product-title {
  font-size: 31px;
  color: #66bb6a;
  margin-left: 0px;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
  padding: 10px;
}

.divide {
  flex-basis: 33%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

.kiwi-image {
  text-align: center;
  flex-basis: 100%;
  margin: 10px;
}

.divide-p {
  display: flex;
  margin-left: 20px;
}

.feature {
  text-align: center;
  padding: 5px 15px;
}

.feature h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.divider {
  width: 1px;
  background-color: #ccc;
  margin: 0 20px;
}

.divider-d {
  width: 0.5px;
  background-color: #ccc;
  margin: 0 20px;
}

.kiwi-image img {
  width: auto;
  height: 340px;
  border-radius: 20px;
}

.fruit-info {
  font-family: Arial, sans-serif;
  color: #333;
}

.info-text {
  font-size: 20px;
  text-align: left;
  text-indent: 2em;
  margin-top: 10px;
  padding: 8px;
}

.word1 {
  font-size: 25px;
}

.word2 {
  font-size: 22px;
}
</style>
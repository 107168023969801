<template>
  <div class="login-container">
    <vue-particles
      class="login-bg"
      color="#164c97"
      :particleOpacity="0.7"
      :particlesNumber="130"
      shapeType="circle"
      :particleSize="6"
      linesColor="#2e5995"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <div class="login-two">
      <el-form :model="form" :rules="rules" ref="loginForm">
        <h1 class="login-title">管理员登录</h1>

        <el-form-item label="用户名" prop="username">
          <el-input v-model="form.username" />
        </el-form-item>

        <el-form-item label="密&emsp;码" prop="password">
          <el-input type="password" v-model="form.password" class="pwdinput" />
        </el-form-item>

        <el-row class="buttons">
          <el-button type="primary" @click="onSubmit">登录</el-button>
          <el-button type="primary" @click="onCancel" class="cancelbut"
            >返回首页</el-button
          >
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { mapState, mapMutations } from "vuex";

export default {
  name: "login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },

      rules: {
        username: { required: true, message: "请输入用户名" },
        password: { required: true, message: "请输入密码" },
      },
    };
  },
  computed: {
    ...mapState(["activeMenu"]),
  },
  methods: {
    ...mapMutations(["setActiveMenu"]),
    onSubmit() {
      let user = this.form.username.trim();
      let pwd = this.form.password.trim();

      if (user === "" || pwd === "") {
        ElMessage.error("各项不能为空");
        return;
      }

      let data = {
        user: user,
        pwd: pwd,
      };

      this.axios
        .post("http://www.cxljr.com:8080/userlogin", this.qs.stringify(data))
        .then((res) => {
          if (res.data.res === "ok") {
            ElMessage({
              message: "登录成功！",
              type: "success",
            });
            setTimeout(() => {
              this.$router.push("/managehome");
            }, 1000);
          } else {
            ElMessage.error("登录失败！");
          }
        });
    },
    onCancel() {
      this.setActiveMenu("home");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.input-field-two {
  margin-left: 13px;
}

.login-container {
  width: 100%;
  color: #cccccc;
  position: relative;
}

.login-title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  margin-left: 50px;
  color: #2e5995;
}

.login-form .el-form-item {
  margin-bottom: 20px;
  font-size: 20px;
}

.button-row {
  display: flex;
  justify-content: space-between;
}

.cancelbut {
  position: absolute;
  left: 160px;
}

.login-two {
  width: 260px;
  height: 270px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto; /* 有了这个就自动居中了 */
}

.login-bg {
  width: 100%;
  background: #a5e2e0;
}
</style>

<template>
  <footer>
    <p>
      {{ message
      }}<a href="https://beian.miit.gov.cn" target="_blank" class="F-number"
        >滇ICP备2023005615号-1</a
      >
    </p>
    <div class="manage-login">
      <a v-if="isLogin" href="/managehome">返回管理系统</a>
      <a v-else href="/login">管理员登录</a>
    </div>
    <div style="width: 300px; margin: 0 auto; padding: 10px 0">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53230102000502"
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "
        ><img src="./../assets/img/备案图标.png" style="float: left" />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          "
        >
          滇公网安备 53230102000502号
        </p></a
      >
    </div>
  </footer>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "Footercom",
  data() {
    return {
      message: "© 2023 楚雄绿巨人生物科技有限公司. All rights reserved.",
    };
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  methods: {
    ...mapMutations(["setisLogin"]),
    judgelogin() {
      this.axios.post("http://www.cxljr.com:8080/islogin").then((res) => {
        if (res.data.res === "yes") {
          this.setisLogin(true);
        }
      });
    },
  },
  mounted() {
    if (this.isLogin) {
      return;
    } else {
      this.judgelogin();
    }
  },
};
</script>

<style scoped>
footer {
  background-color: white;
  padding: 20px;
  text-align: center;
}

.F-number {
  text-decoration: none;
}

.manage-login {
  padding-top: 10px;
}
</style>

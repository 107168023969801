<template>
  <HeadercomVue></HeadercomVue>
  <div class="app-container">
    <div class="contact-header">
      <div class="contact-title" colspan="4">
        <p class="toptext-title">新闻中心</p>
        <p class="product-title">News Center</p>
      </div>
    </div>
    <section class="news-announcement">
      <div class="container">
        <table class="news-table">
          <thead>
            <tr>
              <th>标题</th>
              <th>发布时间</th>
              <th>作者</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="newsItem in displayedNews"
              :key="newsItem.id"
              @click="
                showNewsContent(
                  newsItem.id,
                  newsItem.title,
                  newsItem.author,
                  newsItem.time,
                  newsItem.content
                )
              "
            >
              <td>{{ newsItem.title }}</td>
              <td>{{ newsItem.time }}</td>
              <td>{{ newsItem.author }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="showMoreButton" class="load-more">
          <button @click="showMore">查看更多</button>
        </div>
      </div>
    </section>
  </div>
  <FootercomVue></FootercomVue>
</template>

<script>
import FootercomVue from "@/components/Footercom.vue";
import HeadercomVue from "@/components/Headercom.vue";
import axios from "axios";
import { mapState, mapMutations, useStore } from "vuex";

export default {
  components: {
    FootercomVue,
    HeadercomVue,
  },
  data() {
    return {
      news: [],
      displayedNews: [],
      batchSize: 20,
      showMoreButton: true,
    };
  },
  computed: {
    ...mapState(["isnews"]),
  },
  created() {
    const store = useStore();
    // 使用缓存数据来避免重复请求
    if (store.state.isnews) {
      this.news = store.state.news;
    } else {
      this.fetchNews();
    }
    this.displayedNews = this.news.slice(0, this.batchSize);
  },
  methods: {
    ...mapMutations(["setActiveisnews"]),
    ...mapMutations(["setActivenews"]),
    async fetchNews() {
      try {
        const response = await axios.get("http://www.cxljr.com:8080/getnews");
        const newsData = response.data;
        this.news = newsData.map((item) => ({
          id: item.nid,
          title: item.title,
          time: item.time,
          content: item.content,
          author: item.author,
        }));
        this.setActivenews(this.news);
        this.displayedNews = this.news.slice(0, this.batchSize);
        this.setActiveisnews(true);
      } catch (error) {
        console.error("Failed to fetch news:", error);
      }
    },
    showMore() {
      const currentBatchSize = this.displayedNews.length;
      const nextBatchSize = currentBatchSize + this.batchSize;
      if (nextBatchSize >= this.news.length) {
        this.showMoreButton = false;
      }
      this.displayedNews = this.news.slice(0, nextBatchSize);
    },
    showNewsContent(id, title, author, time, content) {
      // 通过路由切换到新闻详情页，并传递新闻信息作为参数
      this.$router.push({
        path: `/news/${id}`,
        query: {
          title: title,
          time: time,
          content: content,
          author: author ? author : "",
        },
      });
    },
  },
};
</script>

<style scoped>
.contact-header {
  margin-bottom: -70px;
}

.news-announcement {
  padding: 40px 0;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.news-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  padding-top: 20px;
  text-align: center; /* 将表格居中显示 */
}

.news-table th,
.news-table td {
  border: none;
  padding: 8px;
}

.news-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.news-table tr {
  border-bottom: 1px solid #ccc; /* 添加行间的分隔线 */
}

.load-more {
  text-align: center;
  margin-top: 20px;
}

.load-more button {
  background-color: #009966;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more button:hover {
  background-color: #007755;
}
</style>
